<template>
  <div class="profile--picture">
    <div v-if="user">
      <img :src="user.photo_profile_user" />
      <input
        type="file"
        @change="onFileChange"
        ref="avatar"
        accept="image/*"
        style="display: none"
      />
      <div @click="onPickFile" class="change">{{ $t('profile.changePhoto') }}</div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'change-avatar',
  computed: {
    ...mapState({
      user: (state) => state.global.user,
      baseUrl: (state) => state.global.baseUrl,
    }),
  },
  methods: {
    onPickFile() {
      this.$refs.avatar.click();
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      let formData = new FormData();
      formData.append('photo_profile', files[0]);
      this.upload(formData);
    },
    async upload(formData) {
      try {
        await this.$store.dispatch('v2/profile/changeAvatar', formData);
        this.$store.dispatch('global/fetch');
        await this.$swal(this.$t('general.success'), this.$t('profile.updateSuccess'), 'success');
        // eslint-disable-next-line no-empty
      } catch (e) {}
    },
  },
};
</script>

<style scoped></style>
