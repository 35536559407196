<template>
  <section class="profile--wrapper">
    <div class="title--text">
      {{ $t('home.sidebarMenu.profile') }}
    </div>
    <change-avatar />
    <form @submit.prevent="updateProfile">
      <div class="input--wrapper">
        <div class="form--group">
          <div class="left--col">
            <label for="name">
              <span>{{ $t('profile.name') }}</span>
            </label>
          </div>
          <div class="right--col">
            <input id="name" v-model="fullname" placeholder="Nama" class="basic--input" />
          </div>
        </div>
        <div class="form--group">
          <div class="left--col">
            <label for="email">
              <span>{{ $t('loginRegister.email') }}</span>
            </label>
          </div>
          <div class="right--col">
            <input readonly id="email" v-model="email" placeholder="Nama" class="basic--input" />
          </div>
        </div>
        <div class="form--group" :class="{ 'has-error': validation.hasError('phone') }">
          <div class="left--col">
            <label for="phone">
              <span>{{ $t('profile.phone') }}</span>
            </label>
          </div>
          <div class="right--col">
            <!--            <cleave-->
            <!--              id="phone"-->
            <!--              :class="{ 'has-error': validation.hasError('phone') }"-->
            <!--              v-model="phone"-->
            <!--              :options="phoneCleaveOption"-->
            <!--              class="basic&#45;&#45;input"-->
            <!--              type="text"-->
            <!--              name="phoneNumber"-->
            <!--              placeholder="No. Handphone"-->
            <!--            />-->
            <client-only>
              <vue-tel-input
                id="phone"
                :class="{ 'has-error': validation.hasError('phone') }"
                :value="phone ? phone : ''"
                @input="phoneInput"
                :validCharactersOnly="true"
                defaultCountry="id"
                :inputOptions="{ placeholder: $t('phoneForm.placeholder') }"
              />
            </client-only>
            <span class="val-error">{{ validation.firstError('phone') }}</span>
          </div>
        </div>
        <regional-form ref="regionalForm" :village-required="true" />
        <div class="form--group">
          <div class="left--col">
            <label for="address">
              <span>{{ $t('profile.address2') }}</span>
            </label>
          </div>
          <div class="right--col">
            <textarea
              v-model="address_line_2"
              placeholder="Alamat"
              id="address"
              class="basic--input"
              type="text"
              rows="4"
            />
          </div>
        </div>
        <div class="form--group">
          <div class="left--col">
            <label for="about_me">
              <span>{{ $t('profile.aboutMe') }}</span>
            </label>
          </div>
          <div class="right--col">
            <textarea
              v-model="about_me"
              placeholder="Tentang Saya"
              id="about_me"
              class="basic--input"
              type="text"
              rows="4"
            />
          </div>
        </div>
      </div>
      <div class="main--btn">
        <button class="btn btn-primary" type="submit">
          {{ $t('profile.saveBtn') }}
        </button>
      </div>
    </form>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import RegionalForm from '@/components/utils/partials/regional-form.vue';
import ChangeAvatar from '@/components/profile/change-avatar.vue';
import { Validator } from 'simple-vue-validator';
import HelperMixin from '@/mixins/helpers';

// import Cleave from 'vue-cleave-component';
// import 'cleave.js/dist/addons/cleave-phone.id.js';

import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';

export default {
  components: {
    RegionalForm,
    // Cleave,
    ChangeAvatar,
    VueTelInput,
  },
  mixins: [HelperMixin],
  data() {
    return {
      phoneCleaveOption: {
        phone: true,
        phoneRegionCode: 'id',
      },
      phoneCountryCode: null,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.global.user,
    }),
    fullname: {
      get() {
        return this.user ? this.user.fullname : null;
      },
      set(fullname) {
        this.$store.commit('global/set_user_input', { fullname });
      },
    },
    email: {
      get() {
        return this.user ? this.user.email : null;
      },
      set(email) {
        this.$store.commit('global/set_user_input', { email });
      },
    },
    phone: {
      get() {
        return this.user ? this.user.phone : null;
      },
      set(phone) {
        this.$store.commit('global/set_user_input', { phone });
      },
    },
    address_line_2: {
      get() {
        return this.user ? this.user.address_line_2 : null;
      },
      set(address_line_2) {
        this.$store.commit('global/set_user_input', { address_line_2 });
      },
    },
    about_me: {
      get() {
        return this.user ? this.user.about_me : null;
      },
      set(about_me) {
        this.$store.commit('global/set_user_input', { about_me });
      },
    },
  },
  validators: {
    phone: {
      validator(value) {
        return Validator.value(value).required(this.$i18n.t('errors.phone.required'));
      },
    },
  },
  async mounted() {
    const regionalData = await this.$store.dispatch('v2/profile/getRegionalData');
    if (regionalData) {
      this.applyRegionalData(regionalData);
    }
  },
  methods: {
    phoneInput(phone, phoneObject) {
      // TODO Perlu perubahan dari sisi API kalau mau pake vue-tel-input
      if (phoneObject && phoneObject.countryCode) {
        this.phoneCountryCode = phoneObject.countryCode;
      } else {
        this.phoneCountryCode = null;
      }
      this.phone = phone;
    },
    async applyRegionalData(regionalData) {
      this.$refs.regionalForm.provinces = regionalData.provinces;
      this.$refs.regionalForm.cities = regionalData.cities;
      this.$refs.regionalForm.districts = regionalData.districts;
      this.$refs.regionalForm.villages = regionalData.villages;

      if (regionalData.province != null) {
        this.$refs.regionalForm.province = regionalData.provinces.find((item) => {
          return item.id === regionalData.province;
        });
      }
      if (regionalData.city != null) {
        this.$refs.regionalForm.city = regionalData.cities.find((item) => {
          return item.id === regionalData.city;
        });
      }
      if (regionalData.district != null) {
        this.$refs.regionalForm.district = regionalData.districts.find((item) => {
          return item.id === regionalData.district;
        });
      }
      if (regionalData.village != null) {
        this.$refs.regionalForm.village = regionalData.villages.find((item) => {
          return item.id === regionalData.village;
        });
      }
    },
    validate() {
      let validationArray = [this.$validate(), this.$refs.regionalForm.validate()];
      return Promise.all(validationArray).then(function (results) {
        return (
          results.filter(function (result) {
            return !result;
          }).length === 0
        );
      });
    },
    async updateProfile() {
      const isValid = await this.validate();
      if (isValid) {
        try {
          this.loading = true;
          let requestData = {
            fullname: this.fullname,
            phone: this.phone,
            phone_country_code: this.phoneCountryCode,
            about_me: this.about_me,
            address_line_2: this.address_line_2,
            city_id: this.$refs.regionalForm.city ? this.$refs.regionalForm.city.id : null,
            district_id: this.$refs.regionalForm.district
              ? this.$refs.regionalForm.district.id
              : null,
            village_id: this.$refs.regionalForm.village ? this.$refs.regionalForm.village.id : null,
          };
          let data = await this.$store.dispatch('v2/profile/updateProfile', requestData);
          if (data) {
            this.$swal('Success', this.$t('profile.updateSuccess'), 'success');
            this.$store.dispatch('global/fetch');
          }
        } finally {
          this.loading = false;
        }
      } else {
        this.scrollToErrorSection(this.$el);
      }
    },
  },
};
</script>
